import { Container, Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 

const ProdottiPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {
  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const config = require("../../constants/constants.js")
  const site_image_prodotti = "../images-"+config.CUSTOMER_CODE+config.URL_IMAGES_CUST_COUPONS;

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;

    const email = form.email.value;
    const password = form.password.value;
    const doNotLogout = form.doNotLogout.checked;

    if (event.currentTarget.checkValidity() === true && email && password) {
        setLoginUserResponseState({ loading: true });
      loginUserApiRequest(email, password, doNotLogout)
        .then((res) => {
            setLoginUserResponseState({ success: res.success, loading: false, error: "" });

            if (res.userLoggedIn) {
                reduxDispatch(setReduxUserState(res.userLoggedIn));
            }

            if (res.success === "user logged in" && !res.userLoggedIn.isAdmin) window.location.assign('/user') 
            else window.location.assign('/admin/orders')

        })
        .catch((er) =>
          setLoginUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data })
        );
    }

    setValidated(true);
  };
  
  return (
    <Container className="px-0"
      style={{
        background:	'#35353a',
        maxWidth: '100%'
      }}   
    >

<Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >

    {/* 
      <Col
        xs={{ order: 2 }}
        md={{ size: 4, order: 1 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_PRODOTTI_SLIDE_1_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_PRODOTTI_SLIDE_1_TITOLO}</h3> 
              <p>{config.PAGE_PRODOTTI_SLIDE_1_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_PRODOTTI_SLIDE_2_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_PRODOTTI_SLIDE_2_TITOLO}</h3> 
              <p>{config.PAGE_PRODOTTI_SLIDE_2_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_PRODOTTI_SLIDE_3_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_PRODOTTI_SLIDE_3_TITOLO}</h3> 
              <p>{config.PAGE_PRODOTTI_SLIDE_3_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 
      </Col>

        */}

      <Col
        xs={{ order: 1 }}
        md={{ size: 7, offset: 1 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

        <h1 
          style={{
            color: "white",
          }}
        >   
          Prodotti
        </h1>

        <Form.Group className="mb-3" controlId="validationCustom01" >
          <Form.Label
            style={{
              color: "gray",
            }}
          >
            <em>&nbsp;<b>{config.PAGE_PRODOTTI_TITOLO}</b></em></Form.Label>
        </Form.Group>

        <Row>
        
        <Col>
            <Card body outline color="success" className="mx-0 my-0"
                style={{
                  background: "black",
                  color: "yellow",
                }}
              >
              <Card.Img crossOrigin="anonymous" variant="top" src={config.PAGE_PRODOTTI_SLIDE_1_URL} 
                style={{
                  height: "300px",
                  width: "auto",                                 }}
              />  
                <Card.Body>
                <Card.Title>
                  {config.PAGE_PRODOTTI_PRODOTTO1_DESCR}
                </Card.Title>
        {/* 
                <Card.Text>
                  {config.PAGE_PRODOTTI_PRODOTTO1_PREZZO}
                </Card.Text>
        */}
                <LinkContainer to="">
                  <Button variant="info">Dettagli</Button>
                </LinkContainer>
              </Card.Body>
            </Card>
          </Col>


          <Col>
            <Card body outline color="success" className="mx-0 my-0"
                style={{
                  background: "black",
                  color: "yellow",
                }}
              >
              <Card.Img crossOrigin="anonymous" variant="top" src={config.PAGE_PRODOTTI_SLIDE_2_URL} 
                style={{
                  height: "300px",
                  width: "auto",
                }}
              />  
              <Card.Body>
                <Card.Title>
                  {config.PAGE_PRODOTTI_PRODOTTO2_DESCR}
                </Card.Title>
        {/* 
                <Card.Text>
                  {config.PAGE_PRODOTTI_PRODOTTO2_PREZZO}
                </Card.Text>
        */}
                <LinkContainer to="">
                  <Button variant="info">Dettagli</Button>
                </LinkContainer>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card body outline color="success" className="mx-0 my-0"
                style={{
                  background: "black",
                  color: "yellow",
                }}
              >
              <Card.Img crossOrigin="anonymous" variant="top" src={config.PAGE_PRODOTTI_SLIDE_3_URL} 
                style={{
                  height: "300px",
                  width: "auto",
                 }}
              />  
              <Card.Body>
                <Card.Title>
                  {config.PAGE_PRODOTTI_PRODOTTO3_DESCR}
                </Card.Title>
        {/* 
                <Card.Text>
                  {config.PAGE_PRODOTTI_PRODOTTO3_PREZZO}
                </Card.Text>
        */}
                <LinkContainer to="">
                  <Button variant="info">Dettagli</Button>
                </LinkContainer>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Col>
    </Row>
    
    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>
  );
};

export default ProdottiPageComponent;
