import {
  Container,
  Row,
  Col,
  Form,
  Alert,
  ListGroup,
  Button,
} from "react-bootstrap";
import CartItemComponent from "../../../components/CartItemComponent";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const UserCartDetailsPageComponent = ({cartItems, itemsCount, cartSubtotal, userInfo,addToCart, removeFromCart, reduxDispatch , getUser, createOrder}) => {

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [userAddress, setUserAddress] = useState(false);
    const [missingAddress, setMissingAddress] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("cc");
//    const paymentMethod="";

    const navigate = useNavigate();
    let pagamento="";

    const changeCount = (productID, count) => {
        reduxDispatch(addToCart(productID, count));
    }

    const removeFromCartHandler = (productID, quantity, price) => {
        if (window.confirm("Sei sicuro?")) {
            reduxDispatch(removeFromCart(productID, quantity, price));
        }
    }

    useEffect(() => {
        getUser()
        .then((data) => {
            if (!data.address || !data.city || !data.country || !data.zipCode || !data.state || !data.phoneNumber) {
                setButtonDisabled(true);
                setMissingAddress(" .In order to make order, fill out your profile with correct address, city etc.");
            } else {
                setUserAddress({address: data.address, city: data.city, country: data.country, zipCode: data.zipCode, state: data.state, phoneNumber: data.phoneNumber})
                setMissingAddress(false);
            }
        })
        .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
    }, [userInfo._id])

    const orderHandler = () => {
        const orderData = {
            orderTotal: {
               itemsCount: itemsCount, 
               cartSubtotal: cartSubtotal,
            },
            cartItems: cartItems.map(item => {
                return {
                    productID: item.productID,
                    name: item.name,
                    price: item.price,
                    image: { path: item.image ? (item.image.path ?? null) : null },
                    quantity: item.quantity,
                    count: item.count,

                }
            }),
            paymentMethod: paymentMethod,
//            paymentMethod: pagamento,
        }
       createOrder(orderData)
       .then(data => {
           if (data) {
               navigate("/user/order-details/" + data._id);
           }
       })
       .catch((err) => console.log(err));
    }

    const choosePayment = (e) => {
        setPaymentMethod(e.target.value);
        pagamento=e.target.value;
    }

  return (
    <Container fluid>
      <Row className="mt-4">
        <h1>Carrello</h1>
        <Col md={8}>
          <br />
          <Row>
            <Col md={6}>
              <h2>Spedizione</h2>
              <b>Nome</b>: {userInfo.name} {userInfo.lastName} <br />
              <b>Indirizzo</b>: {userAddress.address} {userAddress.city} {userAddress.state} {userAddress.zipCode} <br />
              <b>Telefono</b>: {userAddress.phoneNumber}
            </Col>
            <Col md={6}>
              <h2>Metodo di pagamento</h2>
              <Form.Select onChange={choosePayment} >
                <option value="cc">Carta di credito</option>
                <option value="pp">PayPal</option>
                <option value="cod">
                  Pagamento alla consegna (consegna non veloce)
                </option>
              </Form.Select>
            </Col>
            <Row>
              <Col>
                <Alert className="mt-3" variant="danger">
                  Non consegnato
                  {missingAddress}
                </Alert>
              </Col>
              <Col>
                <Alert className="mt-3" variant="success">
                  Non ancora pagato
                </Alert>
              </Col>
            </Row>
          </Row>
          <br />
          <h2>Prodotti</h2>
          <ListGroup variant="flush">
            {cartItems.map((item, idx) => (
              <CartItemComponent item={item} key={idx} removeFromCartHandler={removeFromCartHandler} changeCount={changeCount} />
            ))}
          </ListGroup>
        </Col>
        <Col md={4}>
          <ListGroup>
            <ListGroup.Item>
              <h3>Riepilogo ordine</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              Prezzo degli articoli: <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Spedizione: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Tax: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item className="text-danger">
              Prezzo totale: <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-grid gap-2">
                <Button size="lg" onClick={orderHandler} variant="danger" type="button" disabled={buttonDisabled}>
                  Invia Ordine
                </Button>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default UserCartDetailsPageComponent;
