import { Form } from "react-bootstrap";

const SortOptionsComponent = ({ setSortOption }) => {
  return (
    <Form.Select onChange={(e)=>setSortOption(e.target.value)} aria-label="Default select example">
      <option>ordina per</option>
        {/* 
      <option value="price_1">Prezzo crescente</option>
      <option value="price_-1">Prezzo decrescente</option>
      <option value="rating_-1">Recensioni</option>
        */}
      <option value="name_1">Nome A-Z</option>
      <option value="name_-1">Nome Z-A</option>
    </Form.Select>
  );
};

export default SortOptionsComponent;
