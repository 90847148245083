import AdminCategoriesPageComponent from "./components/AdminCategoriesPageComponent";

import axios from "axios";

const fetchCoupons = async (abctrl) => {
    const {data} = await axios.get("/api/coupons/admin", {
        signal: abctrl.signal,
    });
    return data
}

const deleteCoupon = async (userId) => {
    const { data } = await axios.delete(`/api/coupons/admin/${userId}`);
    return data
}

const AdminCategoriesPage = () => {
  return <AdminCategoriesPageComponent fetchCoupons={fetchCoupons} deleteCoupon={deleteCoupon} />;
};

export default AdminCategoriesPage;