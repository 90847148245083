import {
  Container,
  Row,
  Col,
  Form,
  Alert,
  ListGroup,
  Button,
} from "react-bootstrap";
import CartItemComponent from "../../../components/CartItemComponent";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { logout } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

const OrderDetailsPageComponent = ({ getOrder, markAsDelivered }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("cc");
  const [isPaid, setIsPaid] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);
  const [cartSubtotal, setCartSubtotal] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [orderButtonMessage, setOrderButtonMessage] =
    useState("Imposta come consegnato");
  const [cartItems, setCartItems] = useState([]);

  const styleSpecTitle1 = {
    color: "darkBlue",
  };
  const styleSpecTitle2 = {
    color: "dark",
  };

  useEffect(() => {
    getOrder(id)
      .then((order) => {
        setUserInfo(order.user);
        setPaymentMethod(order.paymentMethod);
        order.isPaid ? setIsPaid(order.paidAt) : setIsPaid(false);
        order.isDelivered
          ? setIsDelivered(order.deliveredAt)
          : setIsDelivered(false);
        setCartSubtotal(order.orderTotal.cartSubtotal);
        if (order.isDelivered) {
          setOrderButtonMessage("Ordine concluso");
          setButtonDisabled(true);
        }
        setCartItems(order.cartItems);
      })
      .catch((er) =>
      dispatch(logout())
        // console.log(
        //   er.response.data.message ? er.response.data.message : er.response.data
        // )
      );
  }, [isDelivered, id]);
  return (
    <Container fluid>
      <Row className="mt-4">
        <h1 style={styleSpecTitle1}>dettagli dell'Ordine</h1>
        <Col md={8}>
          <br />
          <Row>
            <Col md={6}>
              <h2 style={styleSpecTitle2}>Spedizione</h2>
              <b>Nome</b>: {userInfo.name} {userInfo.lastName} <br />
              <b>Indirizzo</b>: {userInfo.address} {userInfo.city}{" "}
              {userInfo.state} {userInfo.zipCode} <br />
              <b>Telefono</b>: {userInfo.phoneNumber}
            </Col>
            <Col md={6}>
              <h2 style={styleSpecTitle2}>Metodo di pagamento</h2>
              <Form.Select value={paymentMethod} disabled={true}>
                <option value="cc">Carta di credito</option>
                <option value="pp">PayPal</option>
                <option value="cod">Pagamento alla consegna (la consegna può ritardare)</option>
              </Form.Select>
            </Col>
            <Row>
              <Col>
                <Alert
                  className="mt-3"
                  variant={isDelivered ? "success" : "danger"}
                >
                  {isDelivered ? (<>Spedito il {isDelivered.substring(8, 10)+'/'+isDelivered.substring(5, 7)+'/'+isDelivered.substring(0, 4)}</>
                  ) : (
                    <>Non spedito</>
                  )}
                </Alert>
              </Col>
              <Col>
                <Alert className="mt-3" variant={isPaid ? "success" : "danger"}>
                  {isPaid ? <>Pagato il {isPaid.substring(8, 10)+'/'+isPaid.substring(5, 7)+'/'+isPaid.substring(0, 4)}</> : <>Non ancora pagato</>}
                </Alert>
              </Col>
            </Row>
          </Row>
          <br />
          <h2>Articoli in ordine</h2>
          <ListGroup variant="flush">
            {cartItems.map((item, idx) => (
              <CartItemComponent key={idx} item={item} orderCreated={true} />
            ))}
          </ListGroup>
        </Col>
        <Col md={4}>
          <ListGroup>
            <ListGroup.Item>
              <h3 style={styleSpecTitle1}>Riepilogo ordine</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              Prezzo articolo:{" "}
              <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Spedizione: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Iva: <span className="fw-bold">inclusa</span>
            </ListGroup.Item>
            <ListGroup.Item className="text-danger">
              Prezzo totale: <span className="fw-bold">€{cartSubtotal}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="d-grid gap-2">
                <Button
                  size="lg"
                  onClick={() => 
                    markAsDelivered(id)
                    .then((res) => {
                       if (res) {
                          setIsDelivered(true); 
                       } 
                    })
                    .catch(er => console.log(er.response.data.message ? er.response.data.message : er.response.data))
                  }
                  disabled={buttonDisabled}
                  variant="danger"
                  type="button"
                >
                  {orderButtonMessage}
                </Button>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderDetailsPageComponent;
